import "mmenu-light/src/mmenu-light";

document.addEventListener('DOMContentLoaded', () => {

    // Scroll to Form if Error-Message
    const formError = document.querySelector('.error');

    if (formError !== null) {
        formError.scrollIntoView();
    }

    // MMenu light 
    const menuElement = document.getElementById("mainmenu");

    if (menuElement) {
        const menu = new MmenuLight(menuElement, "(max-width: 1023px)");
        const navigator = menu.navigation();
        const drawer = menu.offcanvas();
        const ocdContent = document.querySelector('.mm-ocd__content').childElementCount;

        if (ocdContent > 0) {
            menuElement.addEventListener('mouseup', (evnt) => {
                evnt.preventDefault();
                buttonBackControl();
            });

            document.getElementById('menu').addEventListener('click', (evnt) => {
                evnt.preventDefault();
                drawer.open();
                buttonBackControl();
            });

            document.getElementById('menu-close').addEventListener('click', (evnt) => {
                evnt.preventDefault();
                drawer.close();
                const subMenuClose = document.getElementById('sub-menu-close');
                subMenuClose.style.display = "none";
            });

            document.getElementById('sub-menu-close').addEventListener('click', (evnt) => {
                evnt.preventDefault();
                navigator.node.click();
                buttonBackControl();
            });

            const navFigures = document.querySelectorAll('.mod_navigation ul > li > .pageimage');

            navFigures.forEach(function (element) {
                const imageLi = document.createElement('li');
                imageLi.classList.add('image-item');

                if (element.nextElementSibling !== null) {
                    element.nextElementSibling.appendChild(imageLi);
                    imageLi.appendChild(element);

                } else {
                    // console.log( element );
                    element.remove();
                }
            });

        }

    }

    const subMenuElements = document.querySelectorAll('#header .level_1 > .submenu');

    for (var i = 0; i < subMenuElements.length; i++) {
        const subMenuElement = subMenuElements[i];
        const subMenuButton = subMenuElement.querySelector('a');
        // console.log(subMenuElement);
        subMenuButton.addEventListener('click', function (evnt) {
            evnt.preventDefault();
            toggleSubNav(subMenuElement);
        });
    }

    document.addEventListener('keyup', (event) => {
        event.preventDefault();
        var code = event.code;
        if (code === 'Escape') {
            closeSubNav();
        }
    }, false);


    window.addEventListener('click', function (evnt) {
        const searchOpenSubNavs = document.querySelectorAll('.subMenuIsOpen');

        if (searchOpenSubNavs.length > 0) {
            evnt.preventDefault();
            if (!document.querySelector('#mainmenu .mod_navigation_inner').contains(evnt.target)) {
                closeSubNav(searchOpenSubNavs);
            }
        }
    });

    const karussells = document.querySelectorAll('.slider.block');

    karussells.forEach(karussell => {
        // Für jedes Karussell die Navigation einrichten
        const nextButton = karussell.querySelector('.slider-nav-next');
        const prevButton = karussell.querySelector('.slider-nav:not(.slider-nav-next)');

        nextButton?.addEventListener('click', () => scrollKarussell(karussell, true));
        prevButton?.addEventListener('click', () => scrollKarussell(karussell, false));
    });

    function scrollKarussell(karussell, next) {
        const sliderContainer = karussell.querySelector('.slider-containers');

        if (!sliderContainer) return;

        const items = sliderContainer.children;
        const itemCount = items.length;
        const itemWidth = items[0]?.offsetWidth || 0;
        const visibleItemsCount = Math.floor(sliderContainer.offsetWidth / itemWidth);
        const maxScrollPosition = itemWidth * (itemCount - ( visibleItemsCount - 1 ) );
        const currentScrollPosition = sliderContainer.scrollLeft;

        

        if (next) {
            if (currentScrollPosition <= maxScrollPosition) {
                sliderContainer.scrollBy({ left: itemWidth, behavior: 'smooth' });
            } else {
                sliderContainer.scrollTo({ left: 0, behavior: 'smooth' }); // Zurück zum Anfang
            }
        } else {
            
            if (currentScrollPosition >= 0) {
                sliderContainer.scrollBy({ left: -itemWidth, behavior: 'smooth' });
            } else {
                sliderContainer.scrollTo({ left: maxScrollPosition, behavior: 'smooth' }); // Zum Ende scrollen
            }
        }
    }

    // Tastatursteuerung
    document.addEventListener('keydown', (event) => {
        if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
            // Bestimme das Karussell, das am meisten im Viewport sichtbar ist
            let mostVisibleKarussell = null;
            let maxVisibleArea = 0;

            karussells.forEach(karussell => {
                const { top, bottom } = karussell.getBoundingClientRect();
                const visibleArea = Math.min(window.innerHeight, bottom) - Math.max(0, top);

                if (visibleArea > maxVisibleArea) {
                    mostVisibleKarussell = karussell;
                    maxVisibleArea = visibleArea;
                }
            });

            if (mostVisibleKarussell) {
                scrollKarussell(mostVisibleKarussell, event.key === 'ArrowRight');
            }
        }
    });

    // Content Navigation

    const contentMenuElement = document.getElementById("content-navigation");
    if (contentMenuElement) {
        const contentMenuElementItems = contentMenuElement.querySelectorAll("ul.level_1 > li");

        for (let i = 0; i < contentMenuElementItems.length; i++) {
            if (contentMenuElementItems[i].classList.contains('active')) {

                const contentMenuElementItemsLeft = contentMenuElementItems[i].offsetLeft;

                console.log(contentMenuElementItemsLeft);

                contentMenuElementItems[i].scrollIntoView({ block: 'end' });
            }
        }
    }

    const scrollToTopButton = document.getElementById("to-top");
    scrollToTopButton.addEventListener('click', (evnt) => {
        evnt.preventDefault();
        topFunction();
    });

    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
            scrollToTopButton.style.display = "block";
        } else {
            scrollToTopButton.style.display = "none";
        }
    }

    // Test PErformance Lazy Images
    // new PerformanceObserver((list) => {
    //     const latestEntry = list.getEntries().at(-1);
      
    //     if (latestEntry?.element?.getAttribute('loading') == 'lazy') {
    //       console.warn('Warning: LCP element was lazy loaded', latestEntry);
    //     }
    //   }).observe({type: 'largest-contentful-paint', buffered: true});

});

function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

function buttonBackControl() {
    setTimeout(() => {
        const menuElement = document.querySelector("#mainmenu .level_1");
        const subMenuClose = document.getElementById('sub-menu-close');
        if (menuElement.classList.contains('mm-spn--parent')) {
            subMenuClose.style.display = "block";
        } else {
            subMenuClose.style.display = "none";
        }
    }, 50);
}

function toggleSubNav(element) {
    const searchOpenSubNavs = document.querySelectorAll('.subMenuIsOpen');
    const mainElement = document.getElementById('mainmenu');
    const openSubNavs = Array.from(searchOpenSubNavs);

    if (element.classList.contains('subMenuIsOpen')) {
        element.classList.remove('subMenuIsOpen');
        mainElement.classList.remove('mainMenuIsOpen');
    } else if (!element.classList.contains('subMenuIsOpen')) {
        mainElement.classList.add('mainMenuIsOpen');
        for (let i = 0; i < openSubNavs.length; i++) {
            openSubNavs[i].classList.remove('subMenuIsOpen');
        }
        element.classList.add('subMenuIsOpen');
    }
}

function closeSubNav(element) {
    const mainElement = document.getElementById('mainmenu');
    const openSubNavs = Array.from(element);
    for (let i = 0; i < openSubNavs.length; i++) {
        if (openSubNavs[i].classList.contains('subMenuIsOpen')) {
            mainElement.classList.remove('mainMenuIsOpen');
            openSubNavs[i].classList.remove('subMenuIsOpen');
        }
    }
}


